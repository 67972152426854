<template>
  <b-row>
    <b-col lg="12">
      <trading-view/>
    </b-col>

    <b-col lg="12">
      <statistics-group v-if="$havePermission('DETAILS_TRADES')"/>
    </b-col>

    <b-col lg="12">
      <apex-line-chart v-if="false"/>
    </b-col>

    <b-col
        lg="3"
        sm="6"
    >
      <statistic-card-with-area-chart
          v-if="$havePermission('LIST_USERS')"
          icon="UsersIcon"
          :statistic="footerChartData.users.count"
          statistic-title="کاربران سایت"
          :chart-data="footerChartData.users.series"
      />
    </b-col>
    <b-col
        lg="3"
        sm="6"
    >
      <statistic-card-with-area-chart
          v-if="$havePermission('LIST_WITHDRAW')"
          icon="CreditCardIcon"
          color="success"
          :statistic="footerChartData.requests.count"
          statistic-title="درخواست های ثبت شده"
          :chart-data="footerChartData.requests.series"
      />
    </b-col>
    <b-col
        lg="3"
        sm="6"
    >
      <statistic-card-with-area-chart
          v-if="$havePermission('LIST_ORDERS')"
          icon="ShoppingCartIcon"
          color="danger"
          :statistic="footerChartData.orders.count"
          statistic-title="سفارش های ثبت شده"
          :chart-data="footerChartData.orders.series"
      />
    </b-col>
    <b-col
        lg="3"
        sm="6"
    >
      <statistic-card-with-area-chart
          v-if="$havePermission('LIST_TRADES')"
          icon="PackageIcon"
          color="warning"
          :statistic="footerChartData.trade.count"
          statistic-title="معادلات انجام شده"
          :chart-data="footerChartData.trade.series"
      />
    </b-col>
  </b-row>

</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'
import StatisticsGroup from "@/views/Markets/StatisticsGroupVol";
import TradingView from "@/views/Markets/TradingView";
import ApexLineChart from "@/views/Dashboard/ApexLineChart";
import StatisticCardWithAreaChart from "@/views/Dashboard/StatisticCardWithAreaChart";
import _ from 'lodash'

export default {
  name: "Statistics",
  components: {
    StatisticCardWithAreaChart,
    ApexLineChart,
    TradingView,
    StatisticsGroup,
    BRow,
    BCol,
  },
  data() {
    return {
      // Area charts
      subscribersGained: {},
      revenueGenerated: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
      footerChartData: {
        trade: {
          count: '',
          series: [{name: " ", data: [0]}]
        },
        orders: {
          count: '',
          series: [{name: " ", data: [0]}]
        },
        users: {
          count: '',
          series: [{name: " ", data: [0]}]
        },
        requests: {
          count: '',
          series: [{name: " ", data: [0]}]
        }
      }
    }
  },
  methods: {
    async users(){
      if (this.$havePermission('LIST_USERS')) {
        let users, userCount = 0, userArray = []
        for (const url of this.$urls) {
          users = await this.$axios(url[1] + '/users/count')
          userCount += users?.data?.baseDTO?.count || 0
          userArray = _.zipWith(userArray, users?.data?.baseDTO?.array, (a, b) => (a || 0) + (b || 0))
        }
        this.footerChartData.users.count = this.$numFormatter(userCount)
        this.footerChartData.users.series = [{name: " ", data: userArray}]
      }
    },
    async requests(){
      if (this.$havePermission('LIST_WITHDRAW')) {
        let requests, requestCount = 0, requestArray = []
        for (const url of this.$urls) {
          requests = await this.$axios(url[1] + '/wallets/withdrawal-requests-count')
          requestCount += requests?.data?.baseDTO?.count || 0
          requestArray = _.zipWith(requestArray, requests?.data?.baseDTO?.array, (a, b) => (a || 0) + (b || 0))
        }
        this.footerChartData.requests.count = this.$numFormatter(requestCount)
        this.footerChartData.requests.series = [{name: " ", data: requestArray}]
      }
    },
    async orders(){
      if (this.$havePermission('LIST_ORDERS')) {
        let orders, orderCount = 0, orderArray = []
        for (const url of this.$urls) {
          orders = await this.$axios(url[1] + '/orders/count')
          orderCount += orders?.data?.baseDTO?.count || 0
          orderArray = _.zipWith(orderArray, orders?.data?.baseDTO?.array, (a, b) => (a || 0) + (b || 0))
        }
        this.footerChartData.orders.count = this.$numFormatter(orderCount)
        this.footerChartData.orders.series = [{name: " ", data: orderArray}]
      }
    },
    async trades(){
      if (this.$havePermission('LIST_TRADES')) {
        let trade, tradeCount = 0, tradeArray = []
        for (const url of this.$urls) {
          trade = await this.$axios(url[1] + '/trades/count')
          tradeCount += trade?.data?.baseDTO?.count || 0
          tradeArray = _.zipWith(tradeArray, trade?.data?.baseDTO?.array, (a, b) => (a || 0) + (b || 0))
        }
        this.footerChartData.trade.count = this.$numFormatter(tradeCount)
        this.footerChartData.trade.series = [{name: " ", data: tradeArray}]
      }
    }
  },
  async mounted() {
    this.users()
    this.requests()
    this.orders()
    this.trades()
  },
}
</script>

<style scoped>

</style>
